.instabox {
  height: 100%;
  opacity: 0;
  transition: 0.9s;
}

.insta-color {
  color: #fff !important;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 70px;
  border-radius: 20px;
  cursor: pointer;
}

.instagram-embed:hover .instabox {
  opacity: 1;
  cursor: pointer;
}

.insta-video {
  border-radius: 5px !important;
}

@media (min-width: 568px) {
  .instagram-row {
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: nowrap !important;
    padding-bottom: 15px;
  }

  .instagram-row .col-12 {
    width: 18% !important;
  }

  .instagram-row::-webkit-scrollbar {
    width: 1px;
    height: 4px;
  }

  .instagram-row::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

@media (max-width: 568px) {
  .instagram-row {
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: nowrap !important;
    padding-bottom: 15px;
  }

  .instagram-row .col-12 {
    width: 60% !important;
  }
}