.all-category-card {
  height: 100%;
}

.all-category-card .sub-heading {
  font-weight: 500;
}

.all-category-card .icon {
  position: absolute;
  top: 1rem;
  left: 0;
  z-index: 0;
  opacity: 0.1;
  width: 200px;
}

.all-category-card h3 {
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  display: flex;
}

.all-category-card p {
  text-align: justify;
  line-height: 1.8rem;
}

.all-category-img img {
  aspect-ratio: 1/1;
}

.all-category-img .col-6:nth-child(3),
.all-category-img .col-6:nth-child(4),
.all-category-img .col-6:nth-child(5) {
  margin-bottom: 0 !important;
}

.category-add-cart-btn {
  position: absolute;
  bottom: -100%;
  left: 12px;
  right: 0;
  transition: 0.8s;
  padding: 0.5rem;
  text-align: right;
  background: #0000006f;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.category-add-cart-btn p {
  margin-bottom: 0;
}

.category-add-cart-btn p del {
  font-size: 13px;
}

.allcatergory-card:hover .category-add-cart-btn {
  bottom: 0;
}

.all-category-img .ant-skeleton-image,
.all-category-card .ant-skeleton-image {
  height: 200px !important;
}

.all-category-skeleton {
  margin-bottom: -3rem !important;
}

@media (max-width: 578px) {
  .all-category-slider .swiper-slide {
    width: 100% !important;
    margin: 0 auto;
  }

  .all-category-img .col-6:nth-child(3),
  .all-category-img .col-6:nth-child(4),
  .all-category-img .col-6:nth-child(5) {
    margin-bottom: 0.5rem !important;
    padding-right: 0 !important;
  }

  .all-category-img {
    padding: 0;
    margin-top: 12px;
  }

  .all-category-img .col-4:nth-child(3) {
    padding-left: 8px !important;
    padding-right: 0 !important;
  }

  .all-category-img .col-4:nth-child(2) {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .all-category-img .col-4:nth-child(1) {
    padding-left: 0 !important;
    padding-right: 8px !important;
  }

  .category-add-cart-btn {
    display: none;
  }

  .category-padding-main {
    padding: 0 1px;
  }

  .all-category-img .ant-skeleton-image {
    height: auto !important;
    aspect-ratio: 1/1;
  }
}