.review-card {
  display: flex;
}

.review-card .content {
  padding: 0 1rem;
  width: 65%;
}

.review-card .img {
  width: 35%;
}

.review-card img {
  aspect-ratio: 1/1;
  object-fit: contain;
}

.review-card .content p:first-child {
  font-size: 1.2rem;
  font-weight: 500;
}

.review-card .content ul {
  display: flex;
  padding: 0;
}

.review-card .content ul li svg {
  color: #ffd700;
}

.review-card .content p {
  margin-bottom: 0;
}

.review-card .content .feedback {
  height: 100px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 10px;
}

.review-card .content .feedback::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f3f3f3;
  height: 3px;
}

.review-card .content .feedback::-webkit-scrollbar {
  width: 2px;
  background-color: #f3f3f3;
  height: 3px;
}

.review-card .content .feedback::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--black-color);
  height: 3px;
}

@media (max-width: 568px) {
  .review-card {
    flex-direction: column;
  }

  .review-card .img,
  .review-card .content {
    width: 100%;
    padding: 0;
  }

  .review-card .content p:first-child {
    margin-top: 10px;
  }

  .review-card .content .feedback {
    height: auto;
  }

}