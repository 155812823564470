.flash-sale-section {
  position: relative;
  background: #fffaf0;
}

.flash-sale-section .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  background-color: var(--theme-color) !important;
}

.flash-sale-section>img {
  position: absolute;
  opacity: 0.2;
  width: 25%;
}

.flash-sale-section .left-icon {
  top: 1rem;
  left: 0;
}

.flash-sale-section .right-icon {
  right: 0;
  bottom: 1rem;
}

.flsh-sale-card .content {
  background-color: #fffaf0;
}