.exclusive-offers-box{
    display: flex;
    flex-wrap: wrap;
}
.exclusive-offers-card{
    width: 20%;
    padding: 0 10px;
}
.exclusive-offers-card .theme-btn{
    padding: 3px 7px;
}
@media (max-width:992px) {
    .exclusive-offers-card{
        width: 33%;
    }
}
@media (max-width:568px) {
    .exclusive-offers-card{
        width: 50%;
        padding: 0;
    }
    .exclusive-offers-card:nth-child(even){
        padding-left: 10px;
    }
    .exclusive-offers-card:nth-child(odd){
        padding-right: 10px;
    }
    .exclusive-offers-card:last-child{
        display: none;
    }
    .exclusive-offers-card .theme-btn{
        padding: 10px 15px;
    }
}