.footer-policy-card {
    display: flex;
    padding: 1rem;
    background: var(--theme-color);
    align-items: center;
    border-radius: 5px;
}

.footer-policy-card img {
    width: 100px;
}

.footer-policy-card .content {
    margin-left: 1rem;
    color: var(--white-color);
}

.footer-policy-card p {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--white-color);
}