@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");

@font-face {
  font-family: Navheading;
  src: url(./assets/fonts/amsterdamthreeslant-axaym.ttf);
}

:root {
  --theme-color: #ad9551;
  --white-color: #fff;
  --black-color: #3b3b3b;
}

body {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Cinzel Decorative", serif; */
  font-family: "Cormorant", serif;
}

a:hover {
  /* color: var(--black-color) !important; */
}

p {
  color: var(--black-color);
}

ul {
  list-style: none;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
  color: var(--black-color);
}

.nav-heading {
  font-size: 2.3rem;
  /* font-family: Navheading; */
  font-family: "Pinyon Script";

  /* font-family: "Cinzel Decorative", serif; */
}

/* .nav-heading span{
  font-family: Navheading;
} */
.theme-btn {
  /* padding: 10px 15px; */
  padding: 0.25rem 0.5rem;
  background: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
}

.theme-btn-disabled {
  background: var(--theme-color);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  opacity: 0.5;
  cursor: not-allowed;
  color: var(--white-color) !important;
}

.main-heading {
  text-transform: capitalize;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.section-spacing-x {
  padding: 0 10px;
}

.aspect-ratio {
  aspect-ratio: 1/1;
}

@media (max-width: 568px) {
  .section-spacing-x-mobile {
    padding: 0;
  }

  .nav-heading {
    font-size: 1rem;
    margin-left: 0px !important;
    padding-left: 9px !important;
  }

  .main-heading {
    margin-bottom: 1rem;
    font-size: calc(1.325rem + 0.9vw);
  }
}

.quantity .quantity-btn button {
  border: none;
  background: transparent;
  color: var(--black-color) !important;
}

.blank-section {
  min-height: 60vh;
}

.breadcrumb .breadcrumb-item {
  font-size: .9rem;
}

/* .nodata img {
  width: 90px;
  height: 90px;
} */

/* swiper dots */
.home-banner-slider .swiper-pagination-bullet-active {
  background: var(--theme-color) !important;
}

.customer-slider-dots .swiper-pagination-bullet-active {
  background: var(--black-color) !important;
}

.home-banner-slider .swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 10px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 10px));
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #fff);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
}

@media (max-width:500px) {
  .home-banner-slider .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color, #fff);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
  }
}

.social-icon-bullet {
  align-items: center !important;
}

.social-icon-bullet li {
  list-style: none !important;
  margin-right: 10px;
}


.date-picker-edit-profile :where(.css-dev-only-do-not-override-mzwlov).ant-picker-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #dee2e6 !important;
}

.nodata-icon {
  width: 100px;
  height: 100px;
}

.payment-box {
  margin-top: -5rem;
}

.payment-box .icon {
  width: 200px;
  height: 200px;
  font-size: 8rem;
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: .4s all;
  color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}

@media (max-width:568px) {
  .social-icon-bullet {
    align-items: flex-start !important;
  }

  .add-btn-mobile {
    font-size: .8rem;
  }
}

/* *** Coupon Code **** */
.coupon-card {
  border: 1px solid var(--theme-color);
  border-top: 4px solid var(--theme-color);
  border-radius: 5px;
  padding: 0 !important;
}

.coupon-card-code {
  width: fit-content;
  background: var(--theme-color);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}

.coupon-main-box .apply-coupon-btn {
  height: auto !important;
  background: var(--theme-color) !important;
  border: none;
  border-top: 1px solid #f3f3f3;
  color: var(--theme-color) !important;
  border-radius: 0 0 5px 5px;
  padding: 0 !important;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width:300px) {
  .quantity .quantity-btn button {
    font-size: 10px !important;
  }
}

/* *** Qr Scanner *** */
.Clientimg {
  bottom: 0;
  width: 100%;
  text-align: center;
  transform: translateY(150px);

}

.Clientimg img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
}

.contact-details {
  margin-top: 155px;
}

.qricon {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background: var(--theme-color);
  font-size: 1.6rem !important;
  margin-right: 0.8rem !important;
}

.qrbg-style {
  height: 300px;
}

.qrbg-style img {
  object-fit: cover;
}

@media (min-width:500px) {
  .fix-size {
    width: 500px;
    border: 1px solid #8080801c;
  }
}

.qr-font {
  font-size: 20px;
}

@media (max-width:500px) {
  .qr-btn-position {
    position: fixed;
    bottom: 0 !important;
  }

  .viedo-iframe-size {
    height: 34vh !important;
  }
}

.location-qr {
  font-size: 19px !important;
}

.viedo-iframe-size {
  height: 100vh;
}


/* SOld out */
.sold-out-btn {
  background: #eeeeee !important;
  color: #caccce !important;
  border-radius: 5px !important;
  cursor: auto !important;
}

.cross-btn-custom {
  right: 0;
  font-weight: 900;
  top: 0;
}