.contact-info-box {
  display: flex;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e3e3e3;
}
.contact-info-box a {
  display: block;
}
.contact-info-box p {
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 400;
}
.contact-info-box .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background: var(--theme-color);
  font-size: 1.8rem;
  margin-right: 1rem;
}
.contact-info-box .icon:first-child svg{
  width: 60px;
}
.contact-form input {
  padding: 0.5rem;
}
.captcha-box {
  position: relative;
}
.captcha-box .captcha {
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 9;
  cursor: pointer;
}
