.gifting-ideas-section {
  padding: 50px 10px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url(../../assets/img/gifting-ideas/giftingbg.jpg);
  color: var(--white-color);
  background-size: cover;
}

.gifting-ideas-section p {
  color: var(--white-color);
}

.gifting-ideas-section img {
  aspect-ratio: 1/1;
}

@media (max-width: 568px) {
  .gifting-ideas-section {
    padding: 50px 0;
  }
}