.home-banner-slider {
  margin: 20px;
  margin-bottom: 0;
}

.home-banner-slider img {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet-active {
  background: var(--theme-color);
}

.home-slider-skeleton {
  padding: 18px !important;
  height: 60vh !important;
  width: 100% !important;
}

@media (max-width: 578px) {
  .home-banner-slider {
    height: auto;
    margin: 10px;
    margin-bottom: 0;
  }

  .home-slider-skeleton {
    height: 20vh !important;
  }
}