.go-to-top {
  position: fixed;
  width: 100%;
  left: 94.6%;
  bottom: 90px;
  height: 20px;
  font-size: 2.5rem;
  z-index: 1;
  cursor: pointer;
  transition: transform 400ms ease;
}

.go-to-top {
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 1.5rem !important;
  border-radius: 50%;
  z-index: 99 !important;
  color: var(--white-color);
  background: var(--theme-color);
  border: none;
}

.go-to-top:after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
}

@media (max-width: 1200px) {
  .go-to-top {
    left: 92% !important;
  }
}

@media (max-width: 568px) {
  .go-to-top {
    left: 82% !important;
  }
}