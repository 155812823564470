.blog-card img {
  border-radius: 5px 5px 0 0;
}

.blog-card .content .title {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.2rem;
  line-height: 1.3;
}

.blog-card .content {
  padding: 10px 0;
}

.blog-card .content p {
  line-height: 1.3;
  margin-bottom: 8px;
}

.blog-card {
  position: relative;
}

.blog-card>span p {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: -5px;
  color: var(--white-color);
}

.blog-card>span b {
  line-height: 1rem;
}

.blog-card>span {
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  border: 1px solid var(--white-color);
}

.blog-des {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-des span {
  font-size: 1rem !important;
}

.blog-section-skeleton .ant-skeleton-image {
  aspect-ratio: 1/1;
  height: 360px !important;
}