.coupon-modal .modal-title {
  text-align: center;
}
.coupon-modal .main-svg {
  margin: 0 auto;
  font-size: 3rem;
}
.coupon-close-btn {
  position: absolute;
  right: 0.5rem;
  top: 0;
  opacity: 1;
}
.coupon-close-btn svg {
  font-size: 2rem;
}

.change-pass-icon {
  margin: 0 auto;
  font-size: 3rem;
  text-align: center;
}
.login-modal-icon {
  font-size: 3rem;
  text-align: center;
}

.lef-rigth-border {
  position: relative;
}
.lef-rigth-border::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 45%;
  transform: translateY(-50%);
  height: 1px;
  background: #777;
}
.lef-rigth-border::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 45%;
  transform: translateY(-50%);
  height: 1px;
  background: #777;
}
