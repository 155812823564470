footer {
  background: var(--theme-color);
  color: var(--white-color);
}

.footer-bottom {
  color: var(--white-color);
  background: var(--theme-color);
}

.footer-bottom a,
.footer-bottom p,
footer a,
footer p {
  color: var(--white-color) !important;
}

.contact-links {
  display: flex;
  justify-content: flex-end;
}

footer .contact-links li>a {
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: var(--theme-color);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .contact-links li:last-child>a {
  margin-right: 0;
}

footer .contact-links li svg {
  color: var(--white-color);
}

.payments-icons {
  display: flex;
  gap: .7rem;
  flex-wrap: wrap;
}

.payments-icons img {
  width: 50px;
}

.footer-sm-icons {
  display: flex;
  padding: 0;
  margin: 0;
}

.footer-sm-icons a {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-sm-icons a svg {
  font-size: 1.5rem;
  color: var(--white-color);
}


@media (max-width:578px) {
  .payments-icons {
    margin: 1rem 0;
  }

  .footer-bottom {
    font-size: .9rem;
    padding-bottom: 1rem !important;
  }

  footer .main-nav-menu-links>li {
    text-align: left !important;
    padding: 0 !important;
  }

}